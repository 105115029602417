import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HeaderEnglish from "./Assets/Com/Header/HeaderEnglish";
import HeaderArabic from "./Assets/Com/Header/HeaderArabic";
import Footer from "./Assets/Com/Footer/Footer";
import FooterAr from "./Assets/Com/Footer/FooterAr";
import Home from "./Pages/Home/Index";
import About from "./Pages/About/Index";
import HeaderArAbout from "./Assets/Com/HeaderAbout/HeaderArAbout";
import HeaderEnAbout from "./Assets/Com/HeaderAbout/HeaderEnAbout";
import AboutArContent from "./Pages/AboutAr/AboutAr";
import HomeArContent from "./Pages/HomeAr/HomeAr";
import PrivacyPolicyEn from "./Pages/PrivacyPolicy/PrivacyPolicy";
import PrivacyPolicyAr from "./Pages/PrivacyPolicy/PrivacyPolicyAr";
import ScrollToTop from "./Assets/Com/ScrollUp";

const routes = [
  {
    path: "/",
    enComponent: <Home />,
    arComponent: <HomeArContent />,
    enHeader: <HeaderEnglish />,
    arHeader: <HeaderArabic />,
  },
  {
    path: "/about",
    enComponent: <About />,
    arComponent: <AboutArContent />,
    enHeader: <HeaderEnAbout />,
    arHeader: <HeaderArAbout />,
  },
  {
    path: "/PrivacyPolicy",
    enComponent: <PrivacyPolicyEn />,
    arComponent: <PrivacyPolicyAr />,
    enHeader: <HeaderEnAbout />,
    arHeader: <HeaderArAbout />,
  },
];

export default function LanguageRoutes() {
  const location = useLocation();
  const isArabic = location.pathname.endsWith("/ar");

  const getHeaderComponent = () => {
    const currentRoute = routes.find(({ path }) => (isArabic ? location.pathname === `${path}/ar` : location.pathname === path));

    if (!currentRoute) return isArabic ? <HeaderArabic /> : <HeaderEnglish />;

    return isArabic ? currentRoute.arHeader : currentRoute.enHeader;
  };

  return (
    <>
      {getHeaderComponent()}
      <Routes>
        {routes.map(({ path, enComponent, arComponent }, index) => (
          <Route key={index} path={path} element={isArabic ? arComponent : enComponent} />
        ))}
        {routes.map(({ path, arComponent }, index) => (
          <Route key={index} path={`${path}/ar`} element={arComponent} />
        ))}
      </Routes>
      {isArabic ? <FooterAr /> : <Footer />}
      <ScrollToTop />
    </>
  );
}
