import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderLogo from "../../Svg/Header_Logo.svg";
import LanguageIcon from "../../Svg/Language_Icon.svg";
import "./HeaderAbout.css";

export default function HeaderEnAbout() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageChange = () => {
    const currentPath = location.pathname;
    const isArabic = currentPath.endsWith("/ar");
    const basePath = isArabic ? currentPath.slice(0, -3) : currentPath;

    const newPath = isArabic ? basePath : `${basePath}${basePath.endsWith("/") ? "ar" : "/ar"}`;
    navigate(newPath, { replace: true });
  };

  return (
    <div className="Header_About_Container">
      <div className="Header_Logo">
        <a href="/">
          <img src={HeaderLogo} alt="Logo" />
        </a>
      </div>
      <div className="Header_Menu">
        <Link to="/">Home</Link>
        <Link to="/about">About Us</Link>
        <div className="Language_Dropdown">
          <button onClick={() => setShowDropdown(!showDropdown)}>
            <img src={LanguageIcon} alt="Language" className="Language_Dropdown_Img" />
          </button>
          {showDropdown && (
            <div className="Dropdown_Menu">
              <button onClick={handleLanguageChange}>Arabic</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
