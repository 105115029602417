import React from "react";
import "./FooterAr.css";
import { Link } from "react-router-dom";
import IosGif from "../../Svg/IOS.gif";
import AndroidGif from "../../Svg/Android.gif";
import LineSpace from "../../Svg/Line_Space.svg";
import InstaIcon from "../../Svg/Insta_Icon.svg";
import FaceIcon from "../../Svg/FaceBook_Icon.svg";
export default function Footer() {
  return (
    <>
      <div className="Footer_Ar">
        <div className="Footer_P1_Ar">
          <h1>
            مواصلة هو تطبيق لحجز الرحلات ويمكنك التواصل مع الفريق على
            <br />
            <a href="mailto:info@mwsla.co">info@mwsla.co</a>
          </h1>

          <Link to="/PrivacyPolicy/ar">
            <p>سياسة الخصوصية</p>
          </Link>
        </div>
        <div className="Footer_P2_Ar">
          <a href="https://apps.apple.com/eg/app/mwsla/id1605197505" target="blank">
            <img src={IosGif} alt="" className="IosFooter_Ar" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.mwsla&pcampaignid=web_share" target="blank">
            <img src={AndroidGif} alt="" className="AndroidFooter_Ar" />
          </a>
        </div>
        <div className="Footer_P3_Ar">
          <img src={LineSpace} alt="" className="Line_Space_Ar" />
        </div>
        <div className="Footer_P4_Ar">
          <div className="Footer_Soical_Ar">
            <a href="https://www.instagram.com/mwsla.eg?igsh=Y2hhY3BwMDZoMXpo" target="blank">
              <img src={InstaIcon} alt="" />
            </a>
            <a href="https://www.facebook.com/mwsla.eg" target="blank">
              <img src={FaceIcon} alt="" />
            </a>
          </div>
          <div className="Footer_Copy_Ar">
            <h1>2024 © جميع الحقوق محفوظة لشركة مواصلة </h1>
          </div>
        </div>
      </div>
    </>
  );
}
