import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PrivacyPolicy.css";
import PRS1P1 from "../../Assets/Svg/PRS1P1.svg";
import PRS1P2 from "../../Assets/Svg/PRS1P2.svg";
import PRS1P3 from "../../Assets/Svg/PRS1P3.svg";
import { Link } from "react-router-dom";

export default function PrivacyPolicyEn() {
  return (
    <div className="PrivacyPolicyC">
      <PRS1 />
      <PRS2 />
    </div>
  );
}

function PRS1() {
  return (
    <>
      <div className="PRS1">
        <div className="PRS1P1">
          <img src={PRS1P1} alt="" className="PRS1P1Img" />
        </div>
        <div className="PRS1P2">
          <img src={PRS1P2} alt="" className="PRS1P2Img" />
        </div>
        <div className="PRS1P3">
          <img src={PRS1P3} alt="" className="PRS1P3Img" />
        </div>
      </div>
    </>
  );
}

function PRS2() {
  const [policyData, setPolicyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const URL = "https://app.mwsla.co/";

  const FetchPolicy = async () => {
    try {
      const response = await axios.get(`${URL}api/staticpages-web`, {
        headers: {
          Accept: "application/json",
        },
      });
      setPolicyData(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch data");
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchPolicy();
  }, []);

  function RefreshPage() {
    window.location.reload();
  }

  if (loading)
    return (
      <div className="">
        <div className="PRS2P1T1 Lo">Please Wait</div>
      </div>
    );
  if (error)
    return (
      <div className="PRS2P1T1 Lo">
        Sorry service is not available now please try again in few moments
        <div className="ErrorBC">
          <div className="Button">
            <button onClick={RefreshPage}>Refresh</button>
          </div>
          <div className="Button">
            <Link to="/">Go home</Link>
          </div>
        </div>
      </div>
    );

  return (
    <>
      <div className="PRS2">
        <div className="PRS2P1">
          <div className="PRS2P1T1">Terms and Condition</div>

          <PRBox Para={policyData.EnglishTerms} />
          {/*  */}
        </div>
        {/*  */}
        <div className="PRS2P1">
          <div className="PRS2P1T1">Privacy & Policy</div>
          <PRBox Para={policyData.privacypolicy} />
        </div>
      </div>
    </>
  );
}

function PRBox({ Para }) {
  const formatText = (text) => {
    if (!text) return "";

    return text.split(".").map((sentence, index) => (
      <React.Fragment key={index}>
        {sentence.trim()}.<br />
      </React.Fragment>
    ));
  };
  return (
    <>
      <div className="PRBox">
        <div className="PRBoxT2">{formatText(Para)}</div>
      </div>
    </>
  );
}
